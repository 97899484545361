import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import theme from '../gatsby-plugin-theme-ui';
import { Tiles, Container, List, Banner, Card, CardWrapper } from '../blocks';
import { Cards } from '../components/card';
import Intro from './intro';
export const wide = true;
export const _frontmatter = {};
const layoutProps = {
  wide,
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Intro mdxType="Intro" />
    <Banner mdxType="Banner">
    </Banner>
    <Banner mdxType="Banner">
      <Cards mdxType="Cards" />
    </Banner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      